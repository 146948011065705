<template>
  <div>
    <div class="main-img-wrapper">
      <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e65b644a-932a-4bd1-aac6-7038db755abe.jpg" alt="主图" class="img-fluid">
      <div class="main-img-caption flex flex-column flex-center">
        <h1 class="main-img-title  animated slideInDown">HONG云</h1>
        <p class="animated slideInDown">专注于零售行业<strong>智慧零售</strong>的会员精准营销系统</p>
      </div>
    </div>
    <section class="feature">
      <b-container>
        <b-row class="flex-center">
          <b-col cols="12" sm="10">
            <div class="feature-img-wrapper pc">
              <img src="../assets/hongyun.gif" alt="功能" class="img-fluid">
            </div>
            <div class="feature-img-wrapper mobile">
              <img src="../assets/hongyun-mobile.gif" alt="功能" class="img-fluid">
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="feature-list">
        <div class="feature-item stripe">
          <b-container>
            <b-row class="flex-around">
              <b-col class="article flex flex-column flex-center animated" md="4" ref="article1" :class="article1">
                <b-row class="flex-center">
                  <b-col cols="12" md="10" lg="6">
                    <article class="content">
                      <h2 class="caption">开卡拓客</h2>
                      <p>随时开卡，仅限60秒</p>
                      <p>随地开卡，场景不受限</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/bf80cbeb-b029-4548-bd39-9bb8e9ead025.png" alt="数字化会员" class="img-fluid">
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item exchange">
          <b-container>
            <b-row class="flex-around">
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/1ae58e54-7d6d-4221-85f6-bb35a73dc21f.png" alt="精准维客" class="img-fluid">
              </b-col>
              <b-col class="article flex flex-column flex-center animated" md="4"  ref="article2" :class="article2">
                <b-row class="flex-center">
                  <b-col cols="12" md="10" lg="6">
                    <article class="content">
                      <h2 class="caption">精准维客</h2>
                      <p>新客转化，稳固客户</p>
                      <p>客户画像，提高销售</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item stripe">
          <b-container>
            <b-row class="flex-around">
              <b-col class="article flex flex-column flex-center animated" md="4" ref="article3" :class="article3">
                <b-row class="flex-center">
                  <b-col cols="12" md="10" lg="6">
                    <article class="content">
                      <h2 class="caption">导购管理</h2>
                      <p>随时查看</p>
                      <p>导购业绩、销售数据</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/34722e3a-fc05-4686-9416-05ebdd3a53bb.png" alt="导购管理" class="img-fluid">
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item exchange">
          <b-container>
            <b-row class="flex-around">
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/eef063ff-9ada-4314-942a-fc4e4feed83a.png" alt="导购管理" class="img-fluid">
              </b-col>
              <b-col class="article flex flex-column flex-center animated" md="4"  ref="article4" :class="article4">
                <b-row class="flex-center">
                  <b-col cols="12" md="10" lg="6">
                    <article class="content">
                      <h2 class="caption">线上商城 </h2>
                      <p>线上线下一体化</p>
                      <p>优惠券促成、留客</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item exchange stripe">
          <b-container>
            <b-row class="flex-around">
              <b-col class="article flex flex-column flex-center animated" md="4"  ref="article4" :class="article4">
                <b-row class="flex-center">
                  <b-col  cols="12" md="10" lg="6" ref="article5" :class="article5">
                    <article class="content">
                      <h2 class="caption">数据分析</h2>
                      <p>月度/季度数据分析</p>
                      <p>店铺经营数据分析报告</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/9bded097-043f-407f-a114-5cf59971fe84.png" alt="数据分析" class="img-fluid">
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      article1: '',
      article2: '',
      article3: '',
      article4: '',
      article5: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
      this.addAnimate({ ele: 'article1', model: 'article1', animate: 'fadeInLeft', scrollDistance })
      this.addAnimate({ ele: 'article2', model: 'article2', animate: 'fadeInRight', scrollDistance })
      this.addAnimate({ ele: 'article3', model: 'article3', animate: 'fadeInLeft', scrollDistance })
      this.addAnimate({ ele: 'article4', model: 'article4', animate: 'fadeInRight', scrollDistance })
      this.addAnimate({ ele: 'article5', model: 'article5', animate: 'fadeInLeft', scrollDistance })
    })
  },
  destoryed () {
    window.removeEventListener('scroll')
  },
  methods: {
    addAnimate ({ ele, model, animate, space = 200, scrollDistance }) {
      if (this.$refs[ele]) {
        this[model] = scrollDistance + space >= this.$refs[ele].offsetTop ? animate : ''
      }
    }
  }
}
</script>

<style scoped lang="less">
@distance: 40px;
.feature-img-wrapper {
  padding: @distance * 2.5 0;
}

.feature-item {
  padding: 40px 0;
}

.content:after {
  position: absolute;
  bottom: -30px;
  left: 0;
  content: '';
  height: 2px;
  width: 30px;
  background: #3167ff;
  border-radius: 30px;
}

.content {
  position: relative;
  // width: max-content;
  // margin: 0 auto;
  // h1, p {
  //   word-break: keep-all;
  // }
  .caption {
    margin-bottom: 20px;
    color: #333;
  }
  p {
    color: #666;
    font-size: 16px;
  }
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 767px) {
  .exchange {
    .article {
      order: -1;
    }
  }

  .pc {
  display: none;
}

.mobile {
  display: block;
}

  .feature-list {
    .feature-item {
      text-align: center;
      .content {
        .caption {
          font-size: 20px;
          text-align: center
        }
      }
      .content:after {
        display: none;
      }
    }
  }

  .article {
    margin-bottom: @distance;
  }
  .feature-img-wrapper {
    padding: 40px 0;
  }

  .img {
    margin: 0 auto;
  }
}
</style>
